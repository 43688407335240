<template>
  <div class="wxFriend" ref="wxFriend">
    <!-- 左侧好友列表 -->
    <div class="friend-list">
      <div class="friend-header">群聊</div>
      <div class="friend-content">
        <div
          class="friend-item"
          v-for="(item, index) in Object.keys(wxFriendList)"
          :key="index"
          :class="{ selected: selectedGroup === item }"
          @click="selectedGroup = item"
          :style="{
            backgroundColor: selectedGroup === item ? '#8fc6ff' : 'inherit',
            color: selectedGroup === item ? '#fff' : 'inherit',
            boxShadow: 'none',
          }"
        >
          {{ item }}
        </div>
      </div>
    </div>

    <!-- 右侧详细信息 -->
    <div class="friend-detail">
      <div class="detail-header">好友详情</div>
      <div
        class="detail-content"
        style="
          display: flex;
          flex-direction: row;
          height: 100%;
          /* overflow-y: auto; */
        "
      >
        <div
          style="
            width: 30%;
            background-color: aqua;
            overflow-y: auto;
            max-height: 100%;
            height: 50vh;
            scrollbar-width: thin;
            scrollbar-color: #8fc6ff #f9fafb;
          "
        >
          <div
            v-for="(member, index) in wxFriendList[selectedGroup]"
            :key="index"
            style="display: flex; align-items: center; padding: 10px"
          >
            <img
              :src="member.avatar"
              alt="成员头像"
              style="
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 10px;
              "
            />
            <span>{{ member.name }}</span>
          </div>
        </div>
        <div style="width: 70%">
          <button @click="testApi">测试api</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "wxFriend",
  data() {
    return {
      wxFriendList: {
        群聊1: [
          {
            name: "张三1",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "张三",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "张三",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "张三",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "张三",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "张三",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "张三",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "张三",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "张三",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "张三",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "张三",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "李四",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "李四",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "李四",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "李四",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "李四",
            avatar: "https://picsum.photos/200/200",
          },
          {
            name: "李四2",
            avatar: "https://picsum.photos/200/200",
          },
        ],
        群聊2: [],
        群聊3: [],
        群聊4: [],
        群聊5: [],
      }, // 测试数据
      selectedGroup: null,
    };
  },
  methods: {
    getWxFriendList() {
      // this.$zcx.div_move(this.$refs.wxFriend);
    },
    testApi() {
      axios
        .post("https://env-00jxgwvpsgbo.dev-hz.cloudbasefunction.cn/tokenapi", {
          action: "tokengenerate",
          tokenData: {
            userId: "xxx",
            role: "admin",
            exp: 1648888888,
          },
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style>
@import "./wxFriend.less";
</style>
