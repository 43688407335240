<template>
  <div class="wx-form">
    <div
      class="wx-right"
      v-if="!socket && !isSocketConnected"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      "
    >
      <div style="display: flex; flex-direction: column; align-items: center">
        <img
          src="../../../assets/image/webimage/wechatm.png"
          alt=""
          style="width: 10vh; height: 10vh"
        />
        <input
          type="password"
          v-model="starBottoken"
          style="width: 20vh; height: 3vh; margin-top: 1vh"
          :style="{
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.4)',
          }"
        />

        <button
          style="
            width: 20vh;
            height: 3vh;
            /* background-color: #12b500; */

            font-size: 1.2vh;
            margin-top: 1vh;
          "
          :style="{
            background: 'linear-gradient(83deg, #a6f75d 0%, #00e3ae 100%)',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.4)',
          }"
          @click="wechatserver"
        >
          进入
        </button>
      </div>
    </div>
    <div class="wx-right" v-if="socket && isSocketConnected">
      <div class="wx-header">
        <!-- 上方导航 -->
        <button
          v-for="(item, index) in wechanform_right_header"
          :key="index"
          :style="{
            backgroundColor: chatbuttonindex === index ? '#ffffff' : '',
            color: chatbuttonindex === index ? '#000' : '',
            boxShadow:
              chatbuttonindex === index ? '0 2px 5px rgba(0, 0, 0, 0.4)' : '',
          }"
          @click="_chatbuttonindex(index)"
        >
          {{ item }}
        </button>
      </div>

      <div class="wx-content">
        <!-- 聊天框 ↑-->
        <!-- 区域1  ↓-->
        <div class="wx-list-content btrr0 bbrr0" style="z-index: 10">
          <div style="margin-top: 1vh; margin-bottom: 1vh">
            <p
              style="text-align: center; margin-bottom: 0.5vh"
              :style="{
                color: isSocketConnected ? 'green' : 'red',
              }"
            >
              {{ isSocketConnected ? "本地服务器已连接" : "本地服务器未连接" }}
            </p>
            <P
              style="text-align: center; margin-bottom: 0.5vh"
              :style="{
                color: sqlDB ? 'green' : 'red',
              }"
            >
              {{ sqlDB ? "会话存档功能已启用" : "会话存档功能已失效" }}
            </P>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <!-- <input
                type="text"
                v-model="connectUrl"
                style="width: 80%; height: 2vh; color: #98a0a8"
              />
              <p
                class="el-icon-refresh-right"
                style="font-size: 2vh"
                @click="wechatserver"
              ></p> -->
            </div>
          </div>
          <!-- 本地服务器区域 -->
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              height: 5vh;
              font-size: 1.5vh;
            "
          >
            <!-- <h3>微信列表</h3> -->
            <p style="color: #12b500">微信列表</p>
            <p
              class="el-icon-refresh-right"
              style="font-size: 1.5vh"
              @click="wechatlistadd"
            ></p>
            <!-- <button style="width: 5vh; height: 2vh; margin-top: 0">获取</button> -->
          </div>

          <div class="wx-list">
            <div
              class="wx-item"
              v-for="(item, index) in wechatlist"
              :key="index"
              @click="_buttonindex(index)"
              :style="{
                backgroundColor: buttonindex === index ? '#e6f2ff' : '',
                boxShadow:
                  buttonindex === index ? '0 2px 5px rgba(0, 0, 0, 0.4)' : '',
              }"
            >
              <img
                :src="item.image"
                alt=""
                style="width: 3vh; height: 3vh; margin-right: 0.5vh"
              />
              {{ item.wxname }}
            </div>
          </div>
          <!-- 微信列表区域 -->
        </div>
        <!-- 区域1  ↑-->
        <!-- 区域2-5 ↓-->
        <div
          style="display: flex; flex-direction: row; height: 100%; width: 100%"
          v-if="chatbuttonindex === 0"
        >
          <!-- 区域2  ↓-->
          <div
            class="wx-sidebar"
            style="
              width: 3%;
              background-color: #e6f2ff;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              z-index: 8;
            "
          >
            <div style="width: 100%; height: 3vh"></div>
            <div
              class="wx-icon"
              :style="{
                backgroundColor: grqulistindex === 0 ? '#8fc6ff' : '',
                boxShadow:
                  grqulistindex === 0 ? '0 2px 5px rgba(0, 0, 0, 0.4)' : '',
              }"
              @click="_grqulistindex(0)"
            >
              <img
                src="../../../assets/image/webimage/wxchat.png"
                alt=""
                style="width: 3vh; height: 3vh"
              />
            </div>
            <div
              class="wx-icon"
              :style="{
                backgroundColor: grqulistindex === 1 ? '#8fc6ff' : '',
                boxShadow:
                  grqulistindex === 1 ? '0 2px 5px rgba(0, 0, 0, 0.4)' : '',
              }"
              @click="_grqulistindex(1)"
            >
              <img
                src="../../../assets/image/webimage/wxchat2.png"
                alt=""
                style="width: 3vh; height: 3vh"
              />
            </div>
          </div>
          <!-- 区域2  ↑-->
          <!-- 微信个人，群，区域 ↑-->
          <!-- 区域3  ↓-->
          <div
            class="wx-list-content"
            style="
              border-radius: 0;
              margin-left: 0;
              display: flex;
              flex-direction: column;
              margin-top: 0;
              z-index: 9;
              width: 10%;
              box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
            "
          >
            <div
              style="
                width: 100%;
                height: 3vh;
                /* background-color: #e6f2ff; */
                z-index: 9;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <input
                type="text"
                name=""
                id=""
                style="width: 80%; height: 2vh; color: #909399"
                v-model="searchtext"
                @input="searchtextInput"
              />
              <transition name="fade">
                <p
                  class="el-icon-search"
                  style="margin-left: 0.5vh"
                  v-if="searchtext === ''"
                ></p>
                <p
                  class="el-icon-circle-close"
                  style="margin-left: 0.5vh"
                  v-if="searchtext !== ''"
                  @click="searchtextdelete"
                ></p>
              </transition>
            </div>
            <!-- 搜索联系人-->

            <div
              v-if="grqulistindex === 0"
              class="wx-list-content btrr0 bbrr0"
              style="
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                margin-left: 0;
                z-index: 8;
                width: 100%;
                box-shadow: none;
                font-size: inherit;
                overflow-y: auto;
              "
              @scroll="handleScroll"
              ref="scrollableDiv"
            >
              <!-- 好友列表 -->
              <button
                v-for="(item, index) in sortedGruserlist"
                :key="index"
                @click="_chatobjdata(item, index)"
                style="position: relative; font-size: inherit"
              >
                <!-- src="../../../assets/image/webimage/wxchat.png" -->
                <!-- 头像 -->
                <img
                  :src="item.avatarUrl"
                  alt=""
                  style="width: 3vh; height: 3vh; margin-right: 0.5vh"
                />
                <!-- 备注名 -->
                <div style="margin-right: 1vh">
                  {{ item.remark !== "" ? item.remark : item.nickname }}
                </div>
                <!-- 悬浮的红色提示标签 -->
                <p
                  style="
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: red;
                    color: white;
                    border-radius: 50%;
                    padding: 0.1vh 0.3vh;
                    font-size: 0.8vh;
                  "
                  v-if="Object.keys(item.msgdata).length > 0"
                >
                  {{
                    Object.keys(item.msgdata).filter(
                      (key) => !item.msgdata[key].isViewed
                    ).length
                  }}
                </p>
                <!-- 悬浮的红色提示标签 -->
              </button>
            </div>

            <!-- 好友列表区域 -->

            <div
              v-if="grqulistindex === 1"
              class="wx-list-content btrr0 bbrr0"
              style="
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                margin-left: 0;
                z-index: 8;
                width: 100%;
                box-shadow: none;
                font-size: inherit;
                overflow-y: auto;
              "
              @scroll="handleScroll"
              ref="scrollableDiv"
            >
              <!-- 群列表 -->
              <button
                v-for="(item, index) in sortedQluserlist"
                :key="index"
                @click="_chatobjdata(item, index)"
                style="position: relative; font-size: inherit"
              >
                <img
                  src="../../../assets/image/webimage/wxchat.png"
                  alt=""
                  style="width: 3vh; height: 3vh; margin-right: 0.5vh"
                />
                <div style="margin-right: 1vh">{{ item.groupName }}</div>
                <p
                  style="
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: red;
                    color: white;
                    border-radius: 50%;
                    padding: 0.1vh 0.3vh;
                    font-size: 0.8vh;
                  "
                  v-if="Object.keys(item.msgdata).length > 0"
                >
                  {{
                    Object.keys(item.msgdata).filter(
                      (key) => !item.msgdata[key].isViewed
                    ).length
                  }}
                </p>
              </button>
            </div>
          </div>
          <!-- 好友列表区域 ↑-->
          <!-- 区域3  ↑-->
          <!-- 区域4 聊天缩小的窗口区域  ↓-->
          <div
            class="wx-list-content"
            style="
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              margin-left: 0;
              z-index: 8;
              background-color: #e6f2ff;
              width: 2vh;
              transition: all 0.3s ease;
              box-shadow: 0;
            "
            :style="{
              width: !msgfrom ? '2vh' : '0%',
            }"
            @click="msgfrom = !msgfrom"
          >
            <p
              class="el-icon-caret-right"
              @click="msgfrom = !msgfrom"
              style="margin-top: 0.5vh"
            ></p>
          </div>
          <div
            class="wx-list-content"
            style="
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              margin-left: 0;
              z-index: 8;
              background-color: #e6f2ff;
              width: 30%;
              transition: all 0.3s ease;
              box-shadow: 0;
            "
            :style="{
              width: msgfrom ? '30%' : '0%',
            }"
          >
            <div
              style="
                display: flex;
                align-items: center;
                width: 100%;
                height: 2vh;
                margin-top: 0.5vh;
                margin-bottom: 0.5vh;
              "
            >
              <p style="margin-left: 0.5vh; color: #12b500">
                {{
                  grqulistindex === 0
                    ? chatobjdata.remark !== ""
                      ? chatobjdata.remark
                      : chatobjdata.nickname
                    : chatobjdata.groupName
                }}
              </p>
              <p style="margin-left: 0.5vh; color: #b8b8b8; font-size: 0.8vh">
                {{
                  grqulistindex === 0
                    ? chatobjdata.wxId !== ""
                      ? chatobjdata.wxId
                      : ""
                    : chatobjdata.groupId
                }}
              </p>
              <p
                class="el-icon-circle-close"
                style="margin-left: auto; margin-right: 0.5vh"
                @click="msgfrom = !msgfrom"
              ></p>
            </div>

            <div
              style="
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 80%;
                /* max-height: 80%; */
                background-color: #fff;
                /* overflow-y: auto; 添加此行以启用垂直滚动 */
              "
              class="wechataaa"
            >
              <div
                :style="{
                  overflowY: 'auto',
                  // backgroundColor: '#12b500',
                  height: msgfrom ? wechatfromsize + 'px' : 'auto',
                }"
                ref="scrollableDiv"
                @click="ce111"
              >
                <div
                  style="
                    width: 100%;
                    margin-top: 0.5vh;
                    /* background-color: #e4e7ed; */
                  "
                  v-for="(item, index) in chatobjdata.msgdata"
                  :key="index"
                >
                  <div
                    style="width: 100%; display: flex; flex-direction: column"
                  >
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                      "
                    >
                      <!-- 头像 -->
                      <img
                        v-if="item.msgSource === 0"
                        :src="
                          grqulistindex === 1
                            ? getqluserurldata(item.finalFromWxId)?.[0]
                                ?.avatarUrl
                            : chatobjdata.avatarUrl
                        "
                        alt=""
                        style="width: 3vh; height: 3vh; margin-left: 1vh"
                      />
                      <span
                        :style="{
                          marginLeft: item.msgSource === 0 ? '0.5vh' : 'auto',
                          marginRight: item.msgSource === 1 ? '0.5vh' : 0,
                          color: '#51ed86',
                        }"
                        :key="item.time"
                      >
                        {{ formatTime(item.time) }}
                      </span>
                      <!-- 机器人头像 -->
                      <img
                        v-if="item.msgSource === 1"
                        :src="wechatlist[buttonindex].image"
                        alt=""
                        style="
                          width: 3vh;
                          height: 3vh;
                          margin-left: 0;
                          margin-right: 1vh;
                        "
                      />

                      <p
                        style="margin-left: 0.5vh; font-size: 1vh"
                        v-if="item.msgSource === 0 && grqulistindex === 1"
                      >
                        {{
                          grqulistindex === 0
                            ? chatobjdata.remark !== ""
                              ? chatobjdata.remark
                              : chatobjdata.nickname
                            : getqluserurldata(item.finalFromWxId)?.[0]
                                ?.displayName !== ""
                            ? getqluserurldata(item.finalFromWxId)?.[0]
                                ?.displayName
                            : getqluserurldata(item.finalFromWxId)[0].nickname
                        }}
                        <span style="font-size: 0.5vh; color: #909399">{{
                          grqulistindex === 1 ? item.finalFromWxId : ""
                        }}</span>
                        <span>
                          {{ item.tiem }}
                        </span>
                      </p>
                    </div>
                    <div
                      style="
                        display: flex;
                        margin-left: 2vh;
                        margin-bottom: 0.5vh;
                        margin-top: 0.3vh;
                      "
                    >
                      <!-- 自己的的头像 -->
                      <div
                        style="
                          display: flex;
                          border: 0.5vh solid #fff;
                          border-radius: 0.5vh;
                          max-width: 60%;
                        "
                        :style="{
                          marginLeft: item.msgSource === 1 ? 'auto' : '0',
                          marginRight: '2vh',
                        }"
                      >
                        <!-- 自己发送的消息 -->
                        <div style="background-color: #fff">
                          <p v-if="item.msgType === 1" style="color: #000">
                            <!-- 文本消息 -->
                            <span
                              style="
                                display: inline-block;
                                word-wrap: break-word;
                                white-space: normal;
                                max-width: 100%;
                              "
                              >{{ item.msgValue }}</span
                            >
                          </p>
                          <p v-if="item.msgType === 3" style="color: #12b500">
                            <!-- 图片消息 -->
                            <img
                              :src="item.imageBase64"
                              alt="缩略图"
                              style="width: 10vh; height: 10vh"
                              @click="imgeclick([item.cdnData, item.msgValue])"
                            />
                          </p>
                          <p v-if="item.msgType === 34" style="color: #12b500">
                            语音消息
                          </p>
                          <p v-if="item.msgType === 43" style="color: #12b500">
                            视频消息
                          </p>
                          <p v-if="item.msgType === 42" style="color: #12b500">
                            文件消息
                          </p>
                        </div>
                        <!-- <p style="background-color: #fff">
                        
                      </p> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 聊天放大的窗口内容-聊天记录 -->
            <div
              style="
                width: 100%;
                height: 12%;
                background-color: #fff;
                /* border-top: 0.2vh solid #adb6bf; */
              "
            >
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="textarea"
                resize="none"
                style="width: 100%; font-size: 1.2vh"
                @keydown.enter.prevent.native="wechatsend"
              >
              </el-input>
            </div>
            <!-- 聊天放大的窗口内容-输入框 -->
            <div
              style="
                width: 100%;
                height: 5%;
                background-color: #fff;
                overflow: visible;
              "
            >
              <button
                style="
                  width: 10vh;
                  height: 3vh;
                  color: #12b500;
                  margin-left: auto;
                  margin-right: 2vh;
                  margin-top: -0.5vh;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 1.2vh;
                "
                @click="wechatsend"
              >
                发送
              </button>
            </div>
            <!-- 聊天放大的窗口内容-发送按钮 -->
          </div>
          <!-- 区域4 聊天放大的窗口内容 ↑-->
          <!-- 区域5 预设群操作 ↓-->
          <div
            class="wx-content"
            style="
              display: flex;
              flex-direction: column;
              margin-top: 0;
              width: 10%;
              margin-left: 0.5vh;
              /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); */
            "
            v-if="grqulistindex === 1 && qluserurldata.length > 0"
          >
            <div
              class="wx-list-content"
              style="
                display: flex;
                align-items: center;
                height: 40%;
                width: 100%;
                margin-left: 0;
                margin-bottom: 0.5vh;
              "
            >
              <div
                style="
                  width: 100%;
                  height: 100%;
                  max-height: 100%;
                  overflow-y: auto;
                "
              >
                <h3 style="margin-left: auto; margin-right: auto">任务列表</h3>
                <div class="quntype">添加任务:</div>
                <div class="quntype">群发任务:</div>
                <div class="quntype">聊天分析:</div>
              </div>
            </div>
            <div
              class="wx-list-content"
              style="
                margin-left: 0;
                z-index: 8;
                width: 100%;
                box-shadow: none;
                font-size: inherit;
                max-height: 60%;
                overflow-y: auto;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
              "
            >
              <div
                style="
                  width: 100%;
                  height: 100%;
                  max-height: 100%;
                  display: flex;
                  flex-direction: column;
                "
              >
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange"
                  size="medium"
                  style="
                    margin-left: 0.3vh;
                    margin-top: 0.3vh;
                    font-size: 2vh;
                    margin-bottom: 0.5vh;
                  "
                >
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      font-size: inherit;
                    "
                  >
                    <span style="font-size: 1.3vh">全选</span>
                    <button
                      style="
                        margin: 0;
                        margin-left: 1vh;
                        width: auto;
                        font-size: 1.3vh;
                        color: #28c445;
                      "
                      v-if="checkedCities.length > 0"
                    >
                      加入添加列表
                    </button>
                  </div>
                </el-checkbox>

                <el-checkbox-group
                  v-model="checkedCities"
                  @change="handleCheckedCitiesChange"
                >
                  <div
                    style="
                      overflow-y: auto;
                      height: 100%;
                      scrollbar-width: none;
                    "
                  >
                    <el-checkbox
                      v-for="(city, index) in qluserurldata"
                      :label="index"
                      :key="index"
                      style="
                        width: 100%;
                        margin-left: 0.3vh;
                        height: 3vh;
                        display: flex;
                        align-items: center;
                        font-size: 1.3vh;
                        /* border: 0.1vh solid #12b500; */
                        height: 3vh;
                        width: 95%;
                        size: 1.3vh;
                      "
                    >
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          height: 100%;
                          /* background-color: #12b500; */
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <img
                          :src="city.avatarUrl"
                          alt=""
                          style="height: 2.5vh; width: 2.5vh"
                        />
                        <span
                          style="
                            line-height: 2.5vh;
                            margin-left: 0.3vh;
                            color: #000;
                            font-size: 1.3vh;
                          "
                          >{{ city.nickname }}</span
                        >
                      </div>
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <!-- 区域5 预设群操作 ↑-->
        </div>
        <!-- 区域2-5 ↑-->
        <!--区域6 批量发送 ↓-->
        <div
          class="wx-list-content"
          v-if="chatbuttonindex !== null && chatbuttonindex !== 0"
          style="width: 30%"
          :style="{
            width:
              chatbuttonindex === 2 || chatbuttonindex === 3 ? '50%' : '30%',
          }"
        >
          <div class="content-plfs">
            <p style="margin-left: 50%">
              {{ wechanform_right_header[chatbuttonindex] }}-设置
            </p>
            <p
              class="el-icon-circle-close"
              style="margin-left: auto; margin-right: 1vh; margin-right: 1vh"
              @click="_chatbuttonindex(0)"
            ></p>
          </div>
          <!-- 群发设置  ↓-->
          <div class="content-plfs-bottom" v-if="chatbuttonindex === 1">
            <div class="plfs-top" style="position: relative; overflow: hidden">
              <h3 style="margin-top: 0.3vh">创建群发任务</h3>
              <div>
                <!-- 设置机器人  ↓-->
                <el-select
                  v-model="plfsdata.robotId"
                  clearable
                  placeholder="设置客服机器人"
                  style="width: 25vh"
                >
                  <el-option
                    v-for="item in wechatlist"
                    :key="item.wxId"
                    :label="item.wxname"
                    :value="item.wxId"
                  >
                  </el-option>
                </el-select>
                <!-- 设置机器人  ↑-->

                <!-- 设置发送周期 ↓ -->
                <div
                  style="
                    width: 25vh;
                    margin-top: 0.3vh;
                    display: flex;
                    flex-direction: column;
                  "
                >
                  <!-- 设置发送周期 ↓ -->
                  <div
                    class="zcx_divborder"
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-start;
                      align-items: center;
                      padding-left: 0.5vh;
                      height: 3vh;
                    "
                  >
                    设置发送周期：新建

                    <el-select
                      v-model="plfsdata.taskType"
                      style="
                        width: 30%;
                        margin-left: 0.2vh;
                        margin-right: 0.2vh;
                      "
                      slot="prepend"
                      size="mini"
                      @clear="el_clear"
                      placeholder="选择"
                      :disabled="
                        plfsdata.fixed_date === null ||
                        plfsdata.fixed_date === ''
                          ? false
                          : true
                      "
                      clearable
                    >
                      <el-option
                        v-for="(value, label) in timeUnits"
                        :key="label"
                        :label="label"
                        :value="value"
                      ></el-option>
                    </el-select>

                    发送任务
                  </div>
                  <!-- 设置发送周期 ↑ -->
                  <!-- 设置月份 ↓ -->
                  <div
                    class="zcx_divborder"
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-start;
                      align-items: center;
                    "
                    v-if="plfsdata.taskType === 'yearly'"
                  >
                    <el-select
                      v-model="plfsdata.taskTime.month"
                      slot="prepend"
                      clearable
                      placeholder="设置月份"
                      style="width: 25vh"
                    >
                      <el-option
                        v-for="value in Array.from(
                          { length: 12 },
                          (_, i) => i + 1
                        )"
                        :key="value"
                        :label="`${value}月`"
                        :value="value"
                      ></el-option>
                    </el-select>
                  </div>
                  <!-- 设置月份 ↑ -->
                  <!-- 设置日期 ↓ -->
                  <div
                    class="zcx_divborder"
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-start;
                      align-items: center;
                    "
                    v-if="
                      plfsdata.taskType === 'monthly' ||
                      plfsdata.taskType === 'yearly'
                    "
                  >
                    <el-select
                      v-model="plfsdata.taskTime.day"
                      slot="prepend"
                      clearable
                      placeholder="设置日期"
                      v-if="
                        plfsdata.taskType === 'monthly' ||
                        plfsdata.taskType === 'yearly'
                      "
                      style="width: 25vh"
                    >
                      <el-option
                        v-for="value in Array.from(
                          { length: 28 },
                          (_, i) => i + 1
                        )"
                        :key="value"
                        :label="`${value}号`"
                        :value="value"
                      ></el-option>
                    </el-select>
                  </div>
                  <!-- 设置日期 ↑ -->
                  <!-- 设置周 ↓ -->
                  <div
                    class="zcx_divborder"
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-start;
                      align-items: center;
                    "
                    v-if="plfsdata.taskType === 'weekly'"
                  >
                    <el-select
                      v-model="plfsdata.taskTime.week"
                      slot="prepend"
                      clearable
                      placeholder="设置日期"
                      style="width: 25vh"
                    >
                      <el-option
                        v-for="value in Array.from(
                          { length: 7 },
                          (_, i) => i + 1
                        )"
                        :key="value"
                        :label="`周${value === 7 ? '日' : value}`"
                        :value="value"
                      ></el-option>
                    </el-select>
                  </div>
                  <!-- 设置周 ↑ -->
                  <!-- 设置小时 ↓ -->
                  <div
                    class="zcx_divborder"
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-start;
                      align-items: center;
                    "
                    v-if="plfsdata.taskType === 'hourly'"
                  >
                    <el-select
                      v-model="plfsdata.taskTime.minute"
                      slot="prepend"
                      clearable
                      placeholder="设置时刻"
                      style="width: 25vh"
                    >
                      <el-option
                        v-for="value in Array.from(
                          { length: 60 },
                          (_, i) => i + 1
                        )"
                        :key="value + ':00'"
                        :label="`${value}分`"
                        :value="value + ':00'"
                      ></el-option>
                    </el-select>
                  </div>
                  <!-- 设置小时 ↑ -->
                  <!-- 设置时间 ↓ -->
                  <div
                    class="zcx_divborder"
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-start;
                      align-items: center;
                    "
                    v-if="
                      plfsdata.taskType !== '' &&
                      plfsdata.taskType !== 'once' &&
                      plfsdata.taskType !== 'minutely' &&
                      plfsdata.taskType !== 'hourly' &&
                      plfsdata.taskType !== 'cycle'
                    "
                  >
                    <el-time-select
                      v-model="plfsdata.taskTime.time"
                      style="width: 25vh"
                      :picker-options="{
                        start: '00:00',
                        step: '00:05',
                        end: '24:00',
                      }"
                      placeholder="选择时间"
                    >
                    </el-time-select>
                  </div>
                  <!-- 设置时间 ↑ -->
                  <!-- 设置循环发送 ↓ -->
                  <div
                    class="zcx_divborder"
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-start;
                      align-items: center;
                    "
                    v-if="plfsdata.taskType === 'cycle'"
                  >
                    <el-input
                      v-model="plfsdata.taskTime.cycletime[0]"
                      placeholder="请输入间隔时间"
                      type="number"
                    ></el-input>
                    <el-select
                      v-model="plfsdata.taskTime.cycletime[1]"
                      style="
                        width: 30%;
                        margin-left: 0.2vh;
                        margin-right: 0.2vh;
                      "
                      slot="prepend"
                      size="mini"
                      placeholder="选择"
                      clearable
                    >
                      <el-option
                        v-for="(value, label) in cycletimelist"
                        :key="label"
                        :label="label"
                        :value="value"
                      ></el-option>
                    </el-select>
                  </div>
                  <!-- 设置循环发送 ↑ -->
                </div>
                <!-- 设置发送周期 ↑ -->

                <!-- 添加任务弹窗  ↓-->
                <div class="tasklist">
                  <button
                    class="tasklist_button"
                    :disabled="plfsdata.msgValue.length > 9"
                    :style="{
                      color:
                        plfsdata.msgValue.length > 9 ? '#909399' : '#8fc6ff',
                    }"
                    @click="instaskdrawer = true"
                  >
                    添加消息任务({{ this.plfsdata.msgValue.length }}/10)
                  </button>
                </div>
                <!-- 添加任务弹窗  ↑-->
                <!-- 任务列表弹窗  ↓-->
                <div class="tasklist">
                  <button class="tasklist_button" @click="drawer = true">
                    展开任务列表
                  </button>
                </div>
                <!-- 任务列表弹窗  ↑-->
                <!-- 选择发送对象  ↓-->
                <div class="tasklist">
                  <button
                    class="tasklist_button"
                    @click="instaContactDrawer = true"
                  >
                    选择发送对象
                  </button>
                </div>
                <!-- 选择发送对象  ↑-->
                <!-- 显示已选联系人列表  ↓-->
                <div
                  class="tasklist"
                  style="flex-direction: row; justify-content: space-evenly"
                  v-if="
                    plfsdata.contact_list.gruserlist.length > 0 ||
                    plfsdata.contact_list.qluserlist.length > 0
                  "
                >
                  <p style="height: 90%; font-size: 1.2vh; color: #28c445">
                    私聊(
                    <span style="font-size: 1vh; color: #f56c6c"
                      >已选{{ plfsdata.contact_list.gruserlist.length }}</span
                    >

                    )
                    <span
                      class="el-icon-delete"
                      @click="plfsdata.contact_list.gruserlist = []"
                    ></span>
                  </p>
                  <p style="height: 90%; font-size: 1.2vh; color: #28c445">
                    群聊(
                    <span style="font-size: 1vh; color: #f56c6c"
                      >已选{{ plfsdata.contact_list.qluserlist.length }}</span
                    >
                    )
                    <span
                      class="el-icon-delete"
                      @click="plfsdata.contact_list.qluserlist = []"
                    ></span>
                  </p>
                </div>
                <!-- 显示已选联系人列表  ↑-->
                <!-- 弹窗  ↓-->
                <!-- 联系人弹窗 ↓-->
                <el-drawer
                  :visible.sync="instaContactDrawer"
                  :withHeader="false"
                  direction="ttb"
                  size="85%"
                  :modal="false"
                  style="position: absolute; top: 0; right: 0"
                  class="Finstaskdrawer_form"
                >
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      width: 100%;
                      height: 100%;
                    "
                  >
                    <!-- 滑动区域 ↓ -->
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        width: 50%;
                        height: 100%;
                        border-right: 0.01vh solid #909399;
                        font-size: 1.2vh;
                      "
                    >
                      <el-table
                        ref="multipleTable"
                        :data="
                          combinedUserList.filter(
                            (data) =>
                              !search ||
                              data.label
                                .toLowerCase()
                                .includes(search.toLowerCase())
                          )
                        "
                        tooltip-effect="dark"
                        style="width: 100%; overflow-y: auto"
                        @selection-change="handleSelectionChange"
                        :header-cell-style="{
                          fontSize: '1.2vh',
                          height: '3vh',
                        }"
                        :cell-style="{ fontSize: '1.2vh', height: '3vh' }"
                        :row-style="{ height: '4vh' }"
                      >
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column
                          prop="label"
                          label="全选"
                          show-overflow-tooltip
                          align="right"
                        >
                          <template slot="header">
                            <el-input
                              v-model="search"
                              size="mini"
                              placeholder="输入关键字搜索"
                            />
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <!-- 滑动区域 ↑ -->
                    <!-- 固定区域 ↓ -->
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        width: 50%;
                        height: 100%;
                      "
                    >
                      <div
                        class="zcx_divdisplay"
                        style="
                          flex-direction: row;
                          height: 8%;
                          justify-content: space-evenly;
                          margin-top: 0.5vh;
                        "
                      >
                        <button
                          style="width: 40%; height: 80%"
                          :style="{
                            color:
                              grqlbutton === 'gruserlist' ? '#8fc6ff' : '#fff',
                            backgroundColor:
                              grqlbutton === 'gruserlist' ? '#fff' : '#8fc6ff',
                            boxShadow:
                              grqlbutton === 'gruserlist'
                                ? '2px 2px 2px 2px rgba(000, 000, 000, 0.1)'
                                : 'none',
                          }"
                          @click="grqlbutton = 'gruserlist'"
                        >
                          好友
                        </button>
                        <button
                          style="width: 40%; height: 80%"
                          :style="{
                            color:
                              grqlbutton === 'qluserlist' ? '#8fc6ff' : '#fff',
                            backgroundColor:
                              grqlbutton === 'qluserlist' ? '#fff' : '#8fc6ff',
                            boxShadow:
                              grqlbutton === 'qluserlist'
                                ? '2px 2px 2px 2px rgba(000, 000, 000, 0.1)'
                                : 'none',
                          }"
                          @click="grqlbutton = 'qluserlist'"
                        >
                          群
                        </button>
                      </div>
                      <!-- 过滤条件 ↓ -->
                      <div class="zcx_divdisplay">
                        <el-input
                          style="
                            width: 82%;
                            height: 7vh;
                            margin-top: 0.3vh;
                            margin-bottom: 0.3vh;
                            font-size: 1.2vh;
                          "
                          type="textarea"
                          resize="none"
                          :autosize="{ minRows: 3, maxRows: 3 }"
                          placeholder="设置过滤条件，格式：张三|李四|王五 每个条件之间用“|”隔断"
                          v-model="ccpdata.filter_condition"
                        >
                        </el-input>
                      </div>
                      <!-- 过滤条件 ↑ -->
                      <!-- 确定按钮 ↓ -->
                      <div
                        class="zcx_divdisplay"
                        style="margin-top: auto; margin-bottom: 0.5vh"
                      >
                        <button style="height: 3vh" @click="ccp">确定</button>
                      </div>
                      <!-- 确定按钮 ↑ -->
                    </div>
                    <!-- 固定区域 ↑ -->
                  </div>
                </el-drawer>
                <!-- 联系人弹窗 ↑-->
                <!-- 添加任务的弹窗 ↓-->
                <el-drawer
                  :visible.sync="instaskdrawer"
                  :withHeader="false"
                  direction="ttb"
                  size="70%"
                  :modal="false"
                  style="position: absolute; top: 0; right: 0"
                  class="Finstaskdrawer_form"
                >
                  <div class="instaskdrawer_form">
                    <h3>添加消息内容</h3>
                    <!-- 选择消息类型 ↓-->
                    <el-select
                      v-model="drawerdata.key"
                      clearable
                      placeholder="选择消息类型"
                      style="width: 25vh"
                    >
                      <el-option
                        v-for="(label, value) in apimsgType"
                        :key="value"
                        :label="label"
                        :value="value"
                      >
                      </el-option>
                    </el-select>
                    <!-- 选择消息类型 ↑-->
                    <!-- 输入消息内容 ↓-->
                    <!-- 文本消息 ↓ -->
                    <el-input
                      class="instaskdrawer_forminput"
                      v-if="drawerdata.key === 'sendTextMessage'"
                      type="textarea"
                      resize="none"
                      :autosize="{ minRows: 15, maxRows: 15 }"
                      placeholder="请输入内容"
                      v-model="drawerdata.value"
                    >
                    </el-input>
                    <!-- 文本消息 ↑-->
                    <!-- 图片消息 ↓ -->
                    <input
                      v-if="drawerdata.key === 'sendImageMessage'"
                      type="file"
                      @change="previewImage"
                      accept="image/*"
                      style="margin-top: 0.5vh"
                    />
                    <img
                      v-if="
                        drawerdata.value &&
                        drawerdata.key === 'sendImageMessage'
                      "
                      :src="drawerdata.value"
                      alt="Image Preview"
                      style="width: 10vh; height: 10vh; margin-top: 1vh"
                    />
                    <!-- 图片消息 ↑ -->
                    <!-- 输入消息内容 ↑-->
                    <button @click="drawerconfirmed">确认</button>
                  </div>
                </el-drawer>
                <!-- 添加任务的弹窗 ↑-->
                <!-- 查看列表的弹窗 ↓-->
                <el-drawer
                  :visible.sync="drawer"
                  :withHeader="false"
                  direction="ttb"
                  size="70%"
                  :modal="false"
                  style="position: absolute; top: 0; right: 0"
                  class="Finstaskdrawer_form"
                >
                  <div class="instaskdrawer_form">
                    <h3>任务列表</h3>
                    <!-- 列表区域 ↓-->
                    <ul
                      class="infinite-list"
                      style="
                        overflow: auto;
                        width: 100%;
                        height: 100%;
                        padding: 0;
                      "
                    >
                      <li
                        v-for="(item, index) in plfsdata.msgValue"
                        :key="index"
                        class="infinite-list-item"
                        style="display: flex; width: 100%"
                      >
                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            border-bottom: 0.1vh solid #909399;
                            margin-top: 0.5vh;
                            overflow: hidden;
                          "
                        >
                          <!-- 任务ID  ↓-->
                          <div
                            class="zcx_divdisplay"
                            style="
                              width: 25%;
                              /* background-color: aqua; */
                              margin-left: 0.5vh;
                            "
                          >
                            <p>
                              任务{{ index + 1 }}({{ apimsgType[item.key] }}):
                            </p>
                          </div>
                          <!-- 任务ID  ↑-->
                          <!-- 任务内容  ↓-->
                          <div
                            class="zcx_divdisplay"
                            style="
                              width: 60%;
                              /* background-color: aqua; */
                              margin-left: 0.5vh;
                            "
                          >
                            <!-- 文本消息显示控件 ↓ -->
                            <el-input
                              style="
                                height: 10vh;
                                margin-top: 0.5vh;
                                margin-bottom: 0.5vh;
                              "
                              type="textarea"
                              resize="none"
                              :autosize="{ minRows: 5, maxRows: 5 }"
                              placeholder="请输入内容"
                              v-model="plfsdata.msgValue[index].value"
                              v-if="item.key === 'sendTextMessage'"
                            >
                            </el-input>
                            <!-- 文本消息显示控件 ↑ -->
                            <!-- 图片消息显示控件 ↓ -->
                            <el-image
                              style="
                                width: 15vh;
                                height: 15vh;
                                margin-bottom: 0.5vh;
                              "
                              fit="contain"
                              v-if="item.key === 'sendImageMessage'"
                              :src="item.value"
                            ></el-image>
                            <!-- 图片消息显示控件 ↑ -->
                          </div>
                          <!-- 任务内容  ↑-->
                          <!-- 删除任务↓ -->
                          <div
                            class="zcx_divdisplay"
                            style="width: 10%; margin-left: auto"
                          >
                            <p
                              class="el-icon-delete"
                              style="margin-right: 2vh"
                              @click="plfsdata.msgValue.splice(index, 1)"
                            ></p>
                          </div>
                          <!-- 删除任务↑ -->
                        </div>
                      </li>
                    </ul>
                    <!-- 列表区域 ↑-->
                  </div>
                </el-drawer>
                <!-- 查看列表的弹窗 ↑-->
                <!-- 弹窗  ↑-->
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: auto;
                  margin-bottom: 1vh;
                  width: 100%;
                "
              >
                <!-- <button>清除</button> -->
                <button
                  @click="Automatic_Message_Confirmation"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.2vh;
                    width: 30%;
                    height: 3vh;
                    background-color: #8fc6ff;
                    color: #fff;
                    border: none;
                    border-radius: 0.5vh;
                  "
                >
                  提交
                </button>
              </div>
            </div>
            <div class="plfs-bottom">群发列表</div>
          </div>
          <!-- 群发设置  ↑-->
          <!-- 任务列表  ↓-->
          <div class="content-plfs-bottom" v-if="chatbuttonindex === 2">
            <div class="plfs-top" style="position: relative; overflow: hidden">
              <h3 style="margin-top: 0.3vh">
                任务列表
                <span class="el-icon-refresh-right" @click="tasklist"></span>
              </h3>
              <!-- 预览弹窗 ↓-->
              <div>
                <el-drawer
                  :visible.sync="previewfrom"
                  :withHeader="false"
                  direction="ttb"
                  size="70%"
                  :modal="false"
                  style="position: absolute; top: 0; right: 0"
                  class="Finstaskdrawer_form"
                >
                  <div
                    class="instaskdrawer_form"
                    style="
                      width: 50%;
                      height: 100%;
                      border-radius: 0.5vh;
                      border: 0.01vh solid #000;
                      background-color: #e6f2ff;
                    "
                  >
                    <h4>预览消息内容</h4>
                    <span style="font-size: 0.8vh; color: #909399"
                      >(预览页，仅供参考)</span
                    >
                    <div
                      v-for="(item, index) in previewfromdata"
                      style="
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        margin-top: 1vh;
                      "
                      :key="index"
                    >
                      <div style="display: flex; flex-direction: column">
                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            margin-left: auto;
                            margin-right: 0.5vh;
                          "
                        >
                          <p>客服机</p>
                          <img
                            src="../../../assets/image/webimage/(28).png"
                            alt=""
                            style="width: 2vh; height: 2vh"
                          />
                        </div>
                        <div
                          style="
                            background-color: #fff;
                            padding: 0.5vh;
                            margin-left: auto;
                            margin-right: 2vh;
                            margin-top: 0.2vh;
                            border-radius: 0.2vh;
                          "
                        >
                          <p v-if="item.key === 'sendTextMessage'">
                            {{ item.value }}
                          </p>
                          <img
                            v-if="item.key === 'sendImageMessage'"
                            :src="item.value"
                            alt=""
                            style="
                              max-width: 100%;
                              max-height: 20vh;
                              height: auto;
                              width: auto;
                            "
                          />
                        </div>
                      </div>
                      <!-- {{ item.key }}/
                    {{ item.value }} -->
                    </div>
                  </div>
                </el-drawer>
              </div>
              <!-- 预览弹窗 ↑-->
              <!-- 表格 ↓-->
              <el-table
                :data="tableData"
                style="width: 100%"
                height="100%"
                :header-cell-style="{
                  fontSize: '1.2vh',
                  height: '3vh',
                }"
                :cell-style="{ fontSize: '1.2vh', height: '3vh' }"
                :row-style="{ height: '4vh' }"
              >
                <el-table-column fixed prop="id" label="ID" width="80">
                </el-table-column>
                <el-table-column label="任务类型" width="100">
                  <template slot-scope="scope">
                    <span>{{ date_type(scope.row.taskType) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="创建日期" width="150">
                  <template slot-scope="scope">
                    <span style="font-size: 0.8vh">{{
                      formatDate(scope.row.createdAt)
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="客服机" width="100">
                  <template slot-scope="scope">
                    {{
                      scope.row.taskData && scope.row.taskData.robotId
                        ? formimage(scope.row.taskData.robotId)[0]
                        : ""
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="预览内容" width="150">
                  <template slot-scope="scope">
                    <button
                      style="
                        background-color: rgba(000, 000, 000, 0);
                        height: 3vh;
                        font-size: 1.2vh;
                        color: #8fc6ff;
                        margin: 0;
                        cursor: pointer;
                      "
                      @click="previewfrom2(scope.row.taskData.msgValue)"
                    >
                      {{ scope.row.taskData.msgValue.length }}条内容
                    </button>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="状态" width="80">
                  <template slot-scope="scope">
                    <span
                      :style="{
                        color:
                          scope.row.status === 'active'
                            ? '#28c445'
                            : scope.row.status === 'stop'
                            ? '#f73e2a'
                            : '',
                      }"
                    >
                      {{ date_type(scope.row.status) }}
                    </span>
                  </template>
                </el-table-column> -->
                <el-table-column
                  label="状态"
                  width="80"
                  :filters="[
                    { text: '执行中', value: 'active' },
                    { text: '停止', value: 'stop' },
                  ]"
                  :filter-method="filterStatus"
                >
                  <template slot-scope="scope">
                    <span
                      :style="{
                        color:
                          scope.row.status === 'active'
                            ? '#28c445'
                            : scope.row.status === 'stop'
                            ? '#f73e2a'
                            : '',
                      }"
                    >
                      {{ date_type(scope.row.status) }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="设置状态" width="140">
                  <template slot-scope="scope">
                    <el-radio-group
                      v-model="scope.row.status"
                      size="mini"
                      border
                      @input="
                        handleClick(scope.row.id, scope.row.status, 'update')
                      "
                    >
                      <el-radio-button label="active">启动</el-radio-button>
                      <el-radio-button label="stop">停止</el-radio-button>
                    </el-radio-group>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="100">
                  <template slot-scope="scope">
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <button
                        class="el-icon-delete"
                        style="
                          background-color: #f56c6c;
                          border-radius: 50%;
                          width: 2vh;
                          height: 2vh;
                          color: #fff;
                        "
                        @click="
                          handleClick(scope.row.id, scope.row.status, 'delete')
                        "
                      ></button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 表格 ↑-->
            </div>
            <div class="plfs-bottom">任务列表</div>
          </div>
          <!-- 任务列表  ↑-->
          <!-- 好友列表  ↓-->
          <div class="content-plfs-bottom" v-if="chatbuttonindex === 3">
            <wxFriend></wxFriend>
          </div>
          <!-- 好友列表  ↑-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import wxFriend from "../wxFriend/wxFriend.vue";
// import { forEach } from "core-js/core/array";
export default {
  components: {
    wxFriend,
  },
  data() {
    return {
      tableData: [],
      search: "",
      instaContactDrawer: false,
      instaskdrawer: false,
      drawer: false,
      previewfrom: false,
      timeUnits: {
        小时: "hourly",
        天: "daily",
        周: "weekly",
        月: "monthly",
        年: "yearly",
        循环: "cycle",
        //     YEARLY: 'yearly',     // 每年循环
        // MONTHLY: 'monthly',   // 每月循环
        // WEEKLY: 'weekly',     // 每周循环
        // DAILY: 'daily',       // 每天循环
        // ONCE: 'once'         // 单次执行
      },
      cycletimelist: {
        秒: "1000",
        分: "60000",
        时: "3600000",
      },
      checkAll: false,
      checkedCities: [],
      isIndeterminate: false,
      wxId: [
        "wxid_q587uwvpu9fk22",
        "wxid_z4q9wt9lxfiu22",
        "wxid_vhjpna9pjsb241",
        "wxid_qael24f2jyli22",
      ], // 机器人微信ID
      wechatlist: [], // 微信列表数据
      buttonlist: ["添加群成员", "删除群成员", "群发消息"], // 聊天上方导航
      wechanform_right_header: ["聊天窗口", "群发", "任务列表", "好友操作"], // 聊天上方导航
      gruserlist: [
        // ["wxid", "张三", "url"],
        // ["wxid", "张三", "url"],
        // ["wxid", "张三", "url"],
      ], // 好友列表数据
      qluserlist: [], // 群列表数据
      buttonindex: 0, // 微信列表按钮index
      grqulistindex: 0, // 好友，群列表 定位index
      chatbuttonindex: 0, // 导航按钮index
      tiemMsg: null, //弹窗消息ID
      connectUrl: "ws://127.0.0.1:10000", // 本地服务器ip
      socket: null, // 本地服务器
      socketStatus: WebSocket.CLOSED, // 本地服务器状态
      msgfrom: false, // 聊天窗口是否打开
      textarea: "", // 聊天输入框
      chatobjdata: "", // 聊天对象数据
      starBottoken:
        "05945ca04818064cc7e6b1adf2c157482f6818ee007fd0e6bad9eb505b9385e2-18797e065b1e34585742d6b1efd40acba",
      messagesList: [], // 消息列表
      searchtext: "", // 搜索文本
      timeoutId: null, // 搜索防抖
      SearchValue: [], // 搜索值
      qluserurldata: [], // 群成员url数据
      wechataaasize: 0,
      sqlDB: true, // 数据库是否连接
      batchId: null, // 批量发送ID
      apimsgType: {
        sendTextMessage: "文本消息",
        sendImageMessage: "图片消息",
        sendVideoMessage: "视频消息",
      }, // 消息类型
      drawerdata: {
        key: null,
        value: null,
      },
      plfsdata: {
        taskType: "", // 任务类型
        taskTime: {
          month: "", // 月份
          week: "", // 周
          day: "", // 日期
          time: "", // 时间
          minute: "", // 分钟
          cycletime: ["", ""], // 循环次数
        }, // 任务时间
        fixed_date: "", // 固定周期
        msgValue: [], // 消息内容
        robotId: null, // 机器人ID
        contact_list: {
          gruserlist: [],
          qluserlist: [],
        }, // 联系人列表
        taskID: null, // 任务ID
      },
      grqlbutton: null, // 选择发送对象，固定位置按键的确定数组gruserlist和qluserlist
      multipleSelection: [], // 多选
      ccpdata: {
        filter_condition: "", //确认联系人之前的过滤条件
      }, //确认联系人的数组
      previewfromdata: [], // 任务预览弹窗数据
    };
  },
  computed: {
    combinedUserList() {
      const data = [];
      // 遍历 gruserlist
      if (this.grqlbutton === "gruserlist") {
        data.push(
          ...Object.values(this.wechatlist[this.buttonindex].gruserlist).map(
            (user) => ({
              label: `${user.remark || "无备注"}/${user.nickname}`, // 假设使用 nickname 或 remark
              key: user.wxId, // 假设有一个方法获取拼音
            })
          )
        );
      } else if (this.grqlbutton === "qluserlist") {
        // 遍历 qluserlist，确保它是一个对象
        data.push(
          ...Object.values(this.wechatlist[this.buttonindex].qluserlist).map(
            (group) => ({
              label: group.groupName, // 假设使用 groupName
              key: group.groupId, // 确保 key 唯一 // 假设有一个方法获取拼音
            })
          )
        );
      }

      return data;
    },
    tableHeight() {
      return this.mallListFromRect ? this.mallListFromRect.height : "100%";
    }, // 表格高度
    isSocketConnected() {
      return this.socket && this.socketStatus === WebSocket.OPEN;
    }, // 本地服务器是否连接
    sortedQluserlist() {
      return this.qluserlist.slice().sort((a, b) => {
        // 使用 slice() 创建副本
        const aHasUnread = Object.keys(a.msgdata).some(
          (key) => !a.msgdata[key].isViewed
        );
        const bHasUnread = Object.keys(b.msgdata).some(
          (key) => !b.msgdata[key].isViewed
        );
        return (bHasUnread ? 1 : 0) - (aHasUnread ? 1 : 0); // 有未读消息的排在前面
      });
    },
    sortedGruserlist() {
      return this.gruserlist.slice().sort((a, b) => {
        // 使用 slice() 创建副本
        const aHasUnread = Object.keys(a.msgdata).some(
          (key) => !a.msgdata[key].isViewed
        );
        const bHasUnread = Object.keys(b.msgdata).some(
          (key) => !b.msgdata[key].isViewed
        );
        return (bHasUnread ? 1 : 0) - (aHasUnread ? 1 : 0); // 有未读消息的排在前面
      });
    },
    wechatfromsize() {
      return this.$zcx.div_move({
        currentTarget: document.querySelector(".wechataaa"),
      }).height;
    },
  },
  async activated() {
    //组件打开
  }, // 页面激活组件打开钩子函数
  deactivated() {
    console.log("组件已经关闭");
    this.socket.close();
  },
  mounted() {
    this.mallListFromRect = this.$zcx.div_move({
      currentTarget: document.querySelector(".wx-form"),
    }); //拿到窗口大小
    this.wechataaasize = this.$zcx.div_move({
      currentTarget: document.querySelector(".wechataaa"),
    });
    this.$nextTick(() => {
      setTimeout(() => {
        const div = this.$refs.scrollableDiv;
        if (div) {
          div.scrollTop = div.scrollHeight; // 滚动到最底部
        }
      }, 100); // 100 毫秒后执行
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize); // 清理事件监听
  },
  methods: {
    filterStatus(value, row) {
      return row.status === value;
    },
    date_type(str) {
      switch (str) {
        case "yearly":
          return "年任务";
        case "monthly":
          return "月任务";
        case "weekly":
          return "周任务";
        case "daily":
          return "日任务";
        case "hourly":
          return "小时时任务";
        case "cycle":
          return "循环任务";
        case "active":
          return "执行中";
        case "stop":
          return "停止";
      }
    },
    previewfrom2(msgValue) {
      this.previewfromdata = msgValue;
      console.log(this.previewfromdata, "cesf2");
      this.previewfrom = true;
    },
    async handleClick(ID, str, type) {
      await this.openret(
        "是否执行操作",
        async () => {
          const d = {
            D: {
              type: type,
              // "update"
              data: { id: ID, status: str },
            },
            H: {
              headers: {
                Authorization: this.starBottoken,
              },
            },
          };
          const ret = await this.wechatsendapi(d, "taskOperation");
          if (type === "delete") {
            await this.tasklist();
          }
          return ret.type.ret;
        },
        type === "update"
          ? () => {
              this.tableData.forEach((item) => {
                if (item.id === ID) {
                  item.status === "active"
                    ? (item.status = "stop")
                    : (item.status = "active");
                }
              });
            }
          : ""
      );
    },
    async openret(messageStr, func, funcfalse) {
      this.$confirm(messageStr, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ret = await func();
          if (ret) {
            this.$message({
              type: "success",
              message: "成功!",
            });
          } else {
            this.$message({
              type: "error",
              message: "失败!",
            });
          }
        })
        .catch(async () => {
          try {
            await funcfalse();
            this.$message({
              type: "info",
              message: "取消!",
            });
          } catch (error) {
            this.$message({
              type: "info",
              message: "取消!",
            });
          }
        });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，所以加1
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
    },
    formimage(wxId) {
      for (const item of this.wechatlist) {
        if (item.wxId === wxId) {
          return [item.wxname, item.image];
        }
      }
    },
    async tasklist() {
      const d = {
        D: {
          type: "tasklist",
        },
        H: {
          headers: {
            Authorization: this.starBottoken,
          },
        },
      };
      const ret = await this.wechatsendapi(d, "tasklist");
      this.tableData = ret.type.ret;
      console.log(this.tableData, "cesf");
    },
    el_clear() {
      this.plfsdata.taskTime = {
        month: "", // 月份
        week: "", // 周
        day: "", // 日期
        time: "", // 时间
        minute: "", // 分钟
        cycletime: ["", ""], // 循环次数
      };
    },
    checkEmptyValues(obj) {
      for (const key in obj) {
        if (obj[key] === "") {
          return key; // 返回第一个空值的键
        }
      }
      return true; // 如果没有空值，返回 true
    },
    async Automatic_Message_Confirmation() {
      const { month, day, time, minute, week } = this.plfsdata.taskTime;
      const messages = {
        month: "请设置月份",
        day: "请设置日期",
        time: "请设置时间",
        minute: "请设置时刻",
        week: "请设置周",
        null: "请选择任务类型",
        cycle: "间隔时间不能小于5秒",
      };
      const showWarningMessage = (key) => {
        this.$message({
          type: "warning",
          message: messages[key],
        });
      };
      let validateTaskvalue = true;
      const validateTask = (values) => {
        const co = this.checkEmptyValues(values);
        if (co !== true) {
          validateTaskvalue = false;
          showWarningMessage(co);
        }
      };
      if (this.plfsdata.robotId === null) {
        this.$message({
          type: "warning",
          message: "请选择机器人",
        });
        return;
      }
      if (this.plfsdata.taskType === "") {
        validateTaskvalue = false;
        showWarningMessage(null);
      } else if (this.plfsdata.taskType === "yearly") {
        validateTask({ month, day, time });
      } else if (this.plfsdata.taskType === "monthly") {
        validateTask({ day, time });
      } else if (this.plfsdata.taskType === "weekly") {
        validateTask({ week, time });
      } else if (this.plfsdata.taskType === "daily") {
        validateTask({ time });
      } else if (this.plfsdata.taskType === "hourly") {
        validateTask({ minute });
      } else if (this.plfsdata.taskType === "cycle") {
        if (
          this.plfsdata.taskTime.cycletime[0] *
            this.plfsdata.taskTime.cycletime[1] <
          5000
        ) {
          validateTaskvalue = false;
          showWarningMessage("cycle");
        }
      }
      if (!validateTaskvalue) {
        return;
      }
      if (this.plfsdata.msgValue.length === 0) {
        this.$message({
          type: "warning",
          message: "请添加任务消息内容",
        });
        return;
      }
      if (
        this.plfsdata.contact_list.gruserlist.length === 0 &&
        this.plfsdata.contact_list.qluserlist.length === 0
      ) {
        this.$message({
          type: "warning",
          message: "请选择联系人",
        });
        return;
      }
      const d = {
        D: {
          type: "task",
          ...this.plfsdata,
          adminid: this.$zcx.zcx_usermr(1).ret.adminid,
        },
        H: {
          headers: {
            Authorization: this.starBottoken,
          },
        },
      };
      const ret = await this.wechatsendapi(d, "task");
      if (ret.type.ret) {
        this.$message({
          type: "success",
          message: "提交成功",
        });
      } else {
        this.$message({
          type: "error",
          message: "提交失败",
        });
      }
      // console.log(this.wechatlist, this.plfsdata, "shisdsa");
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    ccp() {
      if (this.ccpdata.filter_condition === "") {
        // 如果 filter_condition 为空，全部推入
        this.multipleSelection.forEach((item) => {
          if (!this.plfsdata.contact_list[this.grqlbutton].includes(item.key)) {
            this.plfsdata.contact_list[this.grqlbutton].push(item.key);
          }
        });
      } else {
        // 检查 item.label 是否包含在 filter_condition 中
        for (const item of this.multipleSelection) {
          if (
            !this.ccpdata.filter_condition[this.grqlbutton]
              .split("|")
              .some((condition) => item.label.includes(condition))
          ) {
            if (
              !this.plfsdata.contact_list[this.grqlbutton].includes(item.key)
            ) {
              this.plfsdata.contact_list[this.grqlbutton].push(item.key);
            }
          }
        }
      }
      this.instaContactDrawer = false;
      console.log(this.multipleSelection, 22222);
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.drawerdata.value = e.target.result; // 将预览图像的URL存储在data中
        };
        reader.readAsDataURL(file);
      }
    },
    drawerconfirmed() {
      if (
        this.drawerdata.key !== null &&
        this.drawerdata.value !== null &&
        this.drawerdata.value !== ""
      ) {
        this.plfsdata.msgValue.push(this.drawerdata);
        this.drawerdata = {
          key: null,
          value: null,
        };
        this.instaskdrawer = false;
      } else {
        this.$message({
          type: "warning",
          message: "填写错误",
        });
      }
    },
    handleCheckAllChange(val) {
      // console.log(val);
      if (val) {
        this.checkedCities = [];
        this.qluserurldata.forEach((item, index) => {
          // console.log(item, index); // 处理每个 item
          this.checkedCities.push(index);
        });
      } else {
        this.checkedCities = [];
      }
      // this.checkedCities = val ? this.qluserurldata : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      // console.log(value);
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.qluserurldata.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.qluserurldata.length;
    },
    formatTime(timeString) {
      // 假设 timeString 是 '2024/11/20 GMT+8 21:52:08'
      const parts = timeString.split(" "); // 分割字符串
      const timePart = parts[2]; // 获取时间部分 '21:52:08'
      return timePart.substring(0, 5); // 返回 '21:52'
    },
    imgeclick(e) {
      console.log(e);
    },
    ce111() {
      console.log(this.wechatfromsize);
    },
    getqluserurldata(wxid) {
      const userlist = this.qluserurldata.filter((item) => item.wxId === wxid);
      // console.log(userlist, "查询出的群成员信息");
      return userlist;
    },
    searchtextInput() {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.inputselect();
      }, 500);
    },
    inputselect() {
      if (this.searchtext !== "") {
        this.SearchValue = this.$zcx.zcx_MatchObject(
          this.grqulistindex === 0
            ? this.wechatlist[this.buttonindex].gruserlist
            : this.wechatlist[this.buttonindex].qluserlist,
          this.grqulistindex === 0 ? ["nickname", "remark"] : ["groupName"],
          this.searchtext
        );
        if (this.grqulistindex === 0) {
          this.gruserlist = Object.values(this.SearchValue).slice(0, 100);
        } else if (this.grqulistindex === 1) {
          this.qluserlist = Object.values(this.SearchValue).slice(0, 24);
        }

        // console.log(this.SearchValue, 111);
      } else {
        this.SearchValue = [];
        this._buttonindex(this.buttonindex);
        // if (this.grqulistindex === 0) {
        //   this.gruserlist = Object.values(
        //     this.wechatlist[this.buttonindex].gruserlist
        //   ).slice(0, 100);
        // } else if (this.grqulistindex === 1) {
        //   this.qluserlist = Object.values(
        //     this.wechatlist[this.buttonindex].qluserlist
        //   ).slice(0, 24);
        // }
      }
    },
    searchtextdelete() {
      this.searchtext = "";
      this.SearchValue = [];
      this._buttonindex(this.buttonindex);
    },
    handleScroll() {
      // console.log(div.scrollTop, div.clientHeight);
      // console.log(this.wechatlist[this.buttonindex], "cesf");
      const div = this.$refs.scrollableDiv; // 获取引用的 div

      if (div.scrollTop + div.clientHeight >= div.scrollHeight - 10) {
        // console.log("滑动到底部");
        const currentLength =
          this.grqulistindex === 0
            ? this.gruserlist.length
            : this.qluserlist.length; // 获取当前 gruserlist 的长度
        let newItems = "";
        if (this.grqulistindex === 0) {
          newItems =
            this.SearchValue.length > 0
              ? Object.values(this.SearchValue).slice(
                  currentLength,
                  currentLength + 50
                )
              : Object.values(this.wechatlist[this.buttonindex].gruserlist)
                  .slice(currentLength, currentLength + 50)
                  .filter(
                    (newItem) =>
                      !this.gruserlist.some(
                        (existingItem) => existingItem.wxId === newItem.wxId
                      )
                  );

          // console.log(this.gruserlist, newItems, 1111112);
          this.gruserlist.push(...newItems);
        } else if (this.grqulistindex === 1) {
          newItems =
            this.SearchValue.length > 0
              ? Object.values(this.SearchValue).slice(
                  currentLength,
                  currentLength + 5
                )
              : Object.values(this.wechatlist[this.buttonindex].qluserlist)
                  .slice(currentLength, currentLength + 5)
                  .filter(
                    (newItem) =>
                      !this.qluserlist.some(
                        (existingItem) =>
                          existingItem.groupId === newItem.groupId
                      )
                  );
          this.qluserlist.push(...newItems);
        }

        // 从当前长度开始获取下一个 100 个值
        // this.gruserlist.push(...newItems); // 将新值推送到 gruserlist 中
        // console.log(this.gruserlist.length);
      }
    },
    async wechatlistadd() {
      if (this.isSocketConnected) {
        const date = {
          D: {
            type: "getAccountListRequest",
            params: {
              // wxId: wxId,
              // cache: 1,
              status: 1,
            },
          },
          H: {
            headers: {
              Authorization: this.starBottoken,
            },
          },
        };
        // console.log(date, 1111112);
        const ret = await this.wechatsendapi(date);
        // console.log(ret, 1111112);
        for (const item of ret.data.data) {
          if (
            this.wxId.includes(item.wxId) &&
            !this.wechatlist.some(
              (existingItem) => existingItem.wxId === item.wxId
            )
          ) {
            this.wechatlist.push({
              wxId: item.wxId,
              wxname: item.nickname,
              image: item.avatarUrl,
              gruserlist: {},
              qluserlist: {},
            });
          }
        }

        if (ret.data.data.length === 0) {
          this.$message({
            type: "warning",
            message: `未检测到可用微信账号`,
          });
        }
        if (this.wechatlist.length === 0 && ret.data.data.length > 0) {
          this.$message({
            type: "warning",
            message: `当前登录微信账号没有开通权限`,
          });
        }
        // for (const (item,index) of this.wechatlist) {
        //   console.log(item);

        // }
        for (const item of this.wechatlist) {
          const ret = await this._wechatuserListloading(item.wxId);
          item.gruserlist = ret[0];
          item.qluserlist = ret[1];
          // console.log(item.wxname, item);
        }
        this.gruserlist = Object.values(
          this.wechatlist[this.buttonindex].gruserlist
        ).slice(0, 100);
        this.qluserlist = Object.values(
          this.wechatlist[this.buttonindex].qluserlist
        ).slice(0, 24);
        // console.log(this.gruserlist, this.qluserlist, "5161d65sa");
        // this.gruserlist = this.wechatlist[this.grqulistindex].gruserlist;
        // this.qluserlist = this.wechatlist[this.grqulistindex].qluserlist;
        // console.log(this.gruserlist, this.qluserlist, this.wechatlist);
        //  const ret =await this._wechatuserListloading(index)
      }
    },
    getConnectionCount() {
      // 根据您的实际情况实现获取连接数的逻辑
      // 例如：
      return this.connections?.length || 0; // 假设connections是存储连接的数组
    },
    async wechatserver() {
      try {
        this.tiemMsg = this.$zcx.zcx_msgbox(
          this,
          true,
          { str: "正在连接本地服务器...", tiem: 10000 },
          {}
        );
        const tokenstring = JSON.stringify({
          adminid: this.$zcx.zcx_usermr(1).ret.adminid,
          token: this.starBottoken,
        });
        this.socket = new WebSocket(`${this.connectUrl}?token=${tokenstring}`);
        this.socket.onopen = async () => {
          this.TimeoutId = setTimeout(() => {
            this.$message({
              type: "success",
              message: `本地服务器成功`,
            });
          }, 1000);
          this.socketStatus = WebSocket.OPEN;
          await this.wechatlistadd();
        };
        this.socket.onerror = () => {
          clearTimeout(this.TimeoutId);
          this.$message({
            type: "error",
            message: `验证不通过`,
          });
        };
        this.socket.onclose = () => {
          this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);
          clearTimeout(this.TimeoutId);
          this.$message({
            type: "error",
            message: `验证不通过`,
          });

          this.socket = null;
          this.socketStatus = WebSocket.CLOSED; // 更新连接状态
        };
        this.socket.onmessage = (event) => {
          try {
            const message = JSON.parse(event.data);
            if (message.type.type !== "api" && message.type.type !== "task") {
              this.handleOtherMessage(message);
            }
          } catch (error) {
            console.log("收到非 JSON 消息:", event.data);
          }
        };
      } catch (error) {
        console.log(error);
      }
      // return new Promise((resolve, reject) => {
      //   try {
      //     // 如果已经存在连接且处于开启状态，直接返回

      //     if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      //       resolve("WebSocket连接已存在");
      //       return;
      //     }

      //     // this.socket.close();
      //     // // 创建新连接
      //     const tokenstring = JSON.stringify({
      //       adminid: "dl18190972079",
      //       token: this.starBottoken,
      //     });
      //     this.socket = new WebSocket(
      //       `${this.connectUrl}?token=${tokenstring}`
      //     );

      //     const timeout = setTimeout(() => {
      //       reject(new Error("WebSocket连接超时"));
      //     }, 5000); // 5秒超时
      //     // 连接成功
      //     this.socket.onopen = async () => {
      //       clearTimeout(timeout);
      //       this.socketStatus = WebSocket.OPEN;
      //       this.tiemMsg = this.$zcx.zcx_msgbox(
      //         this,
      //         true,
      //         { str: "验证token...", tiem: 10000 },
      //         {}
      //       );
      //       const ret23 = await this.wechatsendapi(
      //         {},
      //         {
      //           type: "verifyToken",
      //           adminid: "dl18190972079",
      //           token: this.starBottoken,
      //         }
      //       );
      //       // console.log(ret23, 1112313);
      //       if (!ret23.data.ret) {
      //         this.socket = null;
      //         this.$message({
      //           type: "error",
      //           message: `Token错误`,
      //         });
      //         this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);
      //         resolve(false);
      //         return;
      //       }
      //       this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);
      //       this.$message({
      //         type: "success",
      //         message: `本地服务器成功`,
      //       });
      //       await this.wechatlistadd();
      //       resolve(true);
      //     };
      //     // 接收消息
      //     this.socket.onmessage = (event) => {
      //       try {
      //         // const data = event.data;
      //         // console.log(data, 1111);
      //         // console.log("收到原始消息:", data);

      //         // // 尝试解析 JSON
      //         const message = JSON.parse(event.data);
      //         // console.log(message);

      //         // // 处理不同类型的消息;
      //         if (message.type.type !== "api") {
      //           // API 响应处理
      //           this.handleOtherMessage(message);
      //         }

      //         // else {
      //         //   // 其他类型消息处理
      //         //   this.handleOtherMessage(message);
      //         // }
      //       } catch (error) {
      //         console.log("收到非 JSON 消息:", event.data);
      //       }
      //     };

      //     // 错误处理

      //     try {
      //       this.socket.onerror = (error) => {
      //         clearTimeout(timeout);
      //         // console.error("WebSocket错误:", error);
      //         this.socketStatus = WebSocket.CLOSED;
      //         resolve(false, error);
      //         // reject(error);
      //       };

      //       this.socket.onclose = () => {
      //         clearTimeout(timeout);
      //         // console.log("WebSocket连接已关闭");

      //         this.socket = null;
      //         this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);
      //         this.$message({
      //           type: "warning",
      //           message: "本地服务器连接失败",
      //         });
      //         this.socketStatus = WebSocket.CLOSED;
      //       };
      //     } catch (error) {
      //       resolve(false);
      //     }
      //   } catch (error) {
      //     reject(error);
      //   }
      // });
    }, // 连接本地服务器
    async wechatsendapi(obj, type_a) {
      return new Promise((resolve, reject) => {
        const requestId = Date.now().toString();
        const msgdata = {
          type: {
            id: requestId,
            type: type_a !== undefined ? type_a : "api",
          },
          data: obj,
        };
        // console.log(msgdata, 111111);

        // 创建一次性消息处理函数
        const messageHandler = (event) => {
          const response = JSON.parse(event.data);
          // console.log(response, 111111);
          if (response.type.id === requestId) {
            // 收到对应请求的响应后，移除这个临时的事件监听器
            this.socket.removeEventListener("message", messageHandler);
            clearTimeout(timeout);
            resolve(response);
          }
        };

        // 添加临时消息处理器
        this.socket.addEventListener("message", messageHandler);
        // console.log(msgdata, 111111);
        // 发送消息
        this.socket.send(JSON.stringify(msgdata));

        // 设置超时
        const timeout = setTimeout(() => {
          this.socket.removeEventListener("message", messageHandler);
          reject(new Error("请求超时"));
        }, 5000);
      });
    },
    async wechatBatchSending(robotId, wxid, data, dateTime) {
      const intervalId = setInterval(async () => {
        const d = {
          D: {
            type: "sendTextMessage",
            params: {
              robotId: robotId,
              wxId: wxid,
              message: data,
            },
          },
          H: {
            headers: {
              Authorization: this.starBottoken,
            },
          },
        };
        const ret = await this.wechatsendapi(d);
        if (ret.data.code === 200) {
          this.$message({
            type: "success",
            message: `批量发送成功`,
          });
        }
      }, dateTime); // 使用 dateTime 作为间隔时间

      // 使用 robotId 和 wxid 组合命名 intervalId
      const namedIntervalId = `${robotId}_${wxid}`;
      this.batchId[namedIntervalId] = intervalId; // 将定时发送的 ID 添加到 this.batchId 中
    },
    async wechatsend() {
      console.log(this.wechatlist, "发送消息的机器人1111111");
      const date = {
        D: {
          type: "sendTextMessage",
          params: {
            robotId: this.chatobjdata.robotId,
            wxId:
              this.grqulistindex === 0
                ? this.chatobjdata.wxId
                : this.chatobjdata.groupId,
            message: this.textarea,
          },
        },
        H: {
          headers: {
            Authorization: this.starBottoken,
          },
        },
      };
      const ret = await this.wechatsendapi(date);
      // const options = {
      //   year: "numeric",
      //   month: "2-digit",
      //   day: "2-digit",
      //   hour: "2-digit",
      //   minute: "2-digit",
      //   second: "2-digit",
      //   timeZoneName: "short",
      // };
      // const localDate = new Date(ret.data.time * 1000).toLocaleString(
      //   "zh-CN",
      //   options
      // );
      if (ret.data.code === 200) {
        this.$message({
          type: "success",
          message: `消息发送成功`,
        });
        // if (this.grqulistindex === 0) {
        //   for (const item of this.wechatlist) {
        //     if (item.wxId === date.D.params.robotId) {
        //       // console.log(item.gruserlist, localDate, "item.gruserlist");
        //       Object.entries(item.gruserlist).forEach(([key, value]) => {
        //         if (key === date.D.params.wxId) {
        //           const msgdatas = {
        //             wechatid: date.D.params.robotId, //接收微信ID
        //             // msgType: fromType, //消息类型
        //             // cn: fromType === "group" ? "群" : "好友",
        //             fromWxId: date.D.params.robotId, //发送者微信ID(好友，群)
        //             finalFromWxId: date.D.params.robotId,
        //             msgValue: date.D.params.message, //消息内容
        //             time: localDate, //消息时间
        //             isViewed: true, //是否已读
        //             ispc: 1, //是否电脑发送的消息
        //             msgSource: 1, //是否自己发送的消息
        //             msgType: 1,
        //           };
        //           value.msgdata = {
        //             ...value.msgdata,
        //             [ret.data.time]: msgdatas,
        //           };
        //           console.log(item, msgdatas, "已发送的消息");
        //           return;
        //         }
        //       });
        //       break;
        //       //       // console.log(ret.data.time, localDate,"时间");
        //     }
        //   }
        // }
        this.textarea = "";
      }
    }, // 发送消息
    handleApiResponse(message) {
      // 处理 API 响应
      console.log("收到 API 响应:", message);
    },

    async handleOtherMessage(message) {
      // 处理其他类型的消息
      if (message.type.type !== "msgobj") {
        return;
      }
      console.log("收到其他消息:", message);
      if ("sqlDB" in message) {
        this.sqlDB = message.sqlDB ? true : false;
      }
      // 检查事件类型
      // 提取消息的基本信息
      const wechatid = message.data.robotId; // 微信ID
      const fromType = message.data.fromType; // 消息来源
      // const fromWxId = message.data.fromWxId; // 发送者的微信ID
      const fromWxId =
        fromType === "private" && message.data.messageSource === 1
          ? message.data.toWxId
          : message.data.fromWxId;
      const finalFromWxId = message.data.finalFromWxId; // 发送者的微信ID(群)
      const messageType = message.data.messageType; // 消息类型
      const messageContent = message.data.message; // 消息内容
      const msgTime = message.data.timeStamp * 1000; // 消息时间
      const ispc = message.data.isPc; // 是否自己发送的消息
      const messageSource = message.data.messageSource; // 是否自己发送的消息
      // const date = new Date(message.data.timeStamp * 1000); // 转换为毫秒

      // 格式化日期
      // const formattedDate = (new Date(message.data.timeStamp * 1000)).toISOString(); // ISO 格式
      // console.log("ISO 格式:", formattedDate);

      // 或者使用其他格式
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
      };
      const localDate = new Date(msgTime).toLocaleString("zh-CN", options); // 本地化格式
      // console.log("本地化格式:", localDate);

      // console.log("接收微信ID:", wechatid);
      // console.log("消息来源:", fromType);

      // console.log("发送者微信ID:", fromWxId);
      let cdnThumbUrl = "";
      let cdnMidImgUrl = "";

      // if (message.data.messageType === 3) {

      // }
      const msgdatas = {
        wechatid: wechatid, //接收微信ID
        msgType: messageType, //消息类型
        cdnData: message.data.cdnData,
        // cn: fromType === "group" ? "群" : "好友",
        fromWxId: fromWxId, //发送者微信ID(好友，群)
        finalFromWxId: fromType === "group" ? finalFromWxId : fromWxId,
        msgValue: messageContent, //消息内容
        imageUrl: messageType === 3 ? [cdnThumbUrl, cdnMidImgUrl] : "",
        imageBase64: message.data.imageBase64,
        time: localDate, //消息时间
        isViewed: false, //是否已读
        ispc: ispc, //是否电脑的消息
        msgSource: messageSource, //是否自己发送的消息
      };
      let found = false;
      for (const item of this.wechatlist) {
        if (item.wxId === wechatid) {
          if (fromType === "group") {
            Object.entries(item.qluserlist).forEach(([key, value]) => {
              if (key === fromWxId) {
                value.msgdata = {
                  ...value.msgdata,
                  [msgTime]: msgdatas,
                };
                if (
                  this.msgfrom &&
                  value.groupId === this.chatobjdata.groupId
                ) {
                  this.$nextTick(() => {
                    const div = this.$refs.scrollableDiv;
                    if (div) {
                      div.scrollTop = div.scrollHeight; // 滚动到最底部
                    }
                  });
                }

                if (this.wechatlist[this.buttonindex].wxId === wechatid) {
                  const exists = this.qluserlist.some(
                    (item) => item.groupId === fromWxId
                  );
                  if (!exists) {
                    this.qluserlist.push(value);
                  }
                }
                found = true;
              }
            });
          } else if (fromType === "private") {
            Object.entries(item.gruserlist).forEach(([key, value]) => {
              if (key === fromWxId) {
                value.msgdata = {
                  ...value.msgdata,
                  [msgTime]: msgdatas,
                };

                // console.log(value, this.chatobjdata, "现在需要的数据");
                if (this.msgfrom && value.wxId === this.chatobjdata.wxId) {
                  this.$nextTick(() => {
                    const div = this.$refs.scrollableDiv;
                    if (div) {
                      div.scrollTop = div.scrollHeight; // 滚动到最底部
                    }
                  });
                }

                if (this.wechatlist[this.buttonindex].wxId === wechatid) {
                  const exists = this.gruserlist.some(
                    (item) => item.wxId === fromWxId
                  );
                  // console.log("找到当前微信", item.gruserlist);
                  if (!exists) {
                    this.gruserlist.push(value);
                  }
                }
                found = true;
              }
            });
          }

          // break;
          if (found) {
            break; // 如果找到匹配项，退出外层循环
          }
          // }
        }
      }
      // console.log(this.wechatlist, 222);
      // await this.storeMessage(msgdatas);

      // switch (messageType) {
      //   case 1: // 文本消息
      //     console.log("文本-消息:", messageContent);
      //     break;
      //   case 3: // 图片消息
      //     console.log("图片-消息:", messageContent);
      //     break;
      //   case 34: // 语音消息
      //     console.log("语音-消息:", messageContent);
      //     break;
      //   case 42: // 名片消息
      //     console.log("名片-消息:", messageContent);
      //     break;
      //   case 43: // 视频消息
      //     console.log("视频-消息:", messageContent);
      //     break;
      //   case 10000: // 系统消息
      //     console.log("系统消息-内容:", messageContent);
      //     break;
      //   default:
      //     console.log("未知消息类型-类型:", messageType);
      //     break;
      // }

      this.$message({
        type: "success",
        message: `来自:(${message.data.fromWxId})的消息：${message.data.message}`,
      });
    },
    async storeMessage(obj) {
      console.log(obj, 111111);
    },
    async analyzeMessage(obj) {
      console.log(obj, 111111);
    },
    async _chatobjdata(item) {
      console.log(item, "聊天窗口数组");
      if (this.grqulistindex === 1) {
        const date = {
          D: {
            type: "queryGroupMemberList",
            params: {
              robotId: item.robotId,
              groupId: item.groupId,
            },
          },
          H: {
            headers: {
              Authorization: this.starBottoken,
            },
          },
        };
        // console.log(date, 1111112);
        const ret = await this.wechatsendapi(date);
        this.qluserurldata = [];
        this.qluserurldata = ret.data.data;
        (this.checkAll = false),
          (this.checkedCities = []),
          (this.isIndeterminate = false),
          console.log(this.qluserurldata, 1111112);
      }

      this.chatobjdata = item;
      this.msgfrom = true;
      this.wechataaasize = this.$zcx.div_move({
        currentTarget: document.querySelector(".wechataaa"),
      }).height;
      // console.log(this.chatobjdata);
    }, // 聊天对象数据
    async _buttonindex(index) {
      this.buttonindex = index;
      this.gruserlist = [];
      this.qluserlist = [];
      // console.log(this.wechatlist[this.buttonindex], "数据");
      // const isviewed=object.values(this.wechatlist[this.buttonindex][this.grqulistindex === 0 ?'gruserlist':'qluserlist']=>(

      // ))
      // const msgis = [];

      Object.values(this.wechatlist[this.buttonindex]["gruserlist"]).forEach(
        (item) => {
          // console.log(item, "数据2");
          const unreadMessages = Object.keys(item.msgdata)
            .filter((item2) => !item.msgdata[item2].isViewed) // 过滤未读消息
            .map((item2) => item.msgdata[item2]); // 获取未读消息对象
          if (unreadMessages.length >= 1) {
            this.gruserlist.push(item); // 将未读消息添加到msgis数组
          }
        }
      );
      const newItems = Object.values(
        this.wechatlist[this.buttonindex].gruserlist
      )
        .slice(0, 100)
        .filter(
          (newItem) =>
            !this.gruserlist.some(
              (existingItem) => existingItem.wxId === newItem.wxId
            )
        );

      // console.log(this.gruserlist, newItems, 1111112);
      this.gruserlist.push(...newItems);

      Object.values(this.wechatlist[this.buttonindex]["qluserlist"]).forEach(
        (item) => {
          // console.log(item, "数据2");
          const unreadMessages = Object.keys(item.msgdata)
            .filter((item2) => !item.msgdata[item2].isViewed) // 过滤未读消息
            .map((item2) => item.msgdata[item2]); // 获取未读消息对象

          if (unreadMessages.length >= 1) {
            // console.log(item, 111111);
            this.qluserlist.push(item);
          }
          // 将未读消息添加到msgis数组
        }
      );
      const newItems2 = Object.values(
        this.wechatlist[this.buttonindex].qluserlist
      )
        .slice(0, 20)
        .filter(
          (newItem) =>
            !this.qluserlist.some(
              (existingItem) => existingItem.groupId === newItem.groupId
            )
        );
      this.qluserlist.push(...newItems2);
      // this[this.grqulistindex === 0 ? "gruserlist" : "qluserlist"].push(
      //   ...msgis
      // );

      // console.log(msgis, "过滤数据");
      // this.wechatlist[this.buttonindex][
      //   this.grqulistindex === 0 ? "gruserlist" : "qluserlist"
      // ].forEach((item) => {
      //   console.log(item, 12);
      // });
      // this.gruserlist.push(
      //   ...Object.values(this.wechatlist[this.buttonindex].gruserlist).slice(
      //     0,
      //     100
      //   )
      // );
      // this.qluserlist.push(...Object.values(
      //   this.wechatlist[this.buttonindex].qluserlist
      // ).slice(0, 24))
      // if (!this.isSocketConnected) {
      //   this.$message({
      //     type: "warning",
      //     message: `本地服务器连接错误`,
      //   });

      //   return;
      // }
      // this.tiemMsg = this.$zcx.zcx_msgbox(
      //   this,
      //   true,
      //   { str: "获取好友列表...", tiem: 1000 },
      //   {}
      // );
      // this.buttonindex = index;
      // this.chatobjdata = "";
      // const date = {
      //   D: {
      //     type: "getFriendList",
      //     params: {
      //       robotId: this.wechatlist[index].wxId,
      //       cache: 1,
      //     },
      //   },
      //   H: {
      //     headers: {
      //       Authorization: this.starBottoken,
      //     },
      //   },
      // };
      // const ret = await this.wechatsendapi(date);

      // this.gruserlist = [];
      // // console.log(ret.data.data);
      // for (const item of ret.data.data) {
      //   this.gruserlist.push([
      //     item.wxId,
      //     item.remark || item.nickname,
      //     item.avatarUrl,
      //   ]);
      // }
      // // console.log(this.gruserlist, "微信好友列表");
      // this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);

      // this.tiemMsg = this.$zcx.zcx_msgbox(
      //   this,
      //   true,
      //   { str: "获取群列表...", tiem: 1000 },
      //   {}
      // );
      // const date2 = {
      //   D: {
      //     type: "getGroupList",
      //     params: {
      //       robotId: this.wechatlist[index].wxId,
      //       cache: 1,
      //     },
      //   },
      //   H: {
      //     headers: {
      //       Authorization: this.starBottoken,
      //     },
      //   },
      // };
      // const ret2 = await this.wechatsendapi(date2);
      // // console.log(ret2.data);
      // this.qluserlist = [];
      // for (const item of ret2.data.data) {
      //   this.qluserlist.push([item.groupId, item.groupName, item.avatarUrl]);
      // }
      // // console.log(this.qluserlist, "微信群列表");
      // this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);
      // return [ret, ret2];
    }, // 获取好友，群列表数据
    async _wechatuserListloading(wxid) {
      if (!this.isSocketConnected) {
        this.$message({
          type: "warning",
          message: `本地服务器连接错误`,
        });

        return;
      }
      this.tiemMsg = this.$zcx.zcx_msgbox(
        this,
        true,
        { str: "获取好友列表...", tiem: 1000 },
        {}
      );
      this.buttonindex = 0;
      // this.chatobjdata = "";
      const date = {
        D: {
          type: "getFriendList",
          params: {
            robotId: wxid,
            cache: 1,
          },
        },
        H: {
          headers: {
            Authorization: this.starBottoken,
          },
        },
      };
      const ret = await this.wechatsendapi(date);

      // this.gruserlist = [];
      // console.log(ret.data.data);
      // for (const item of ret.data.data) {
      //   this.gruserlist.push([
      //     item.wxId,
      //     item.remark || item.nickname,
      //     item.avatarUrl,
      //   ]);
      // }
      // console.log(this.gruserlist, "微信好友列表");
      this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);

      this.tiemMsg = this.$zcx.zcx_msgbox(
        this,
        true,
        { str: "获取群列表...", tiem: 1000 },
        {}
      );
      const date2 = {
        D: {
          type: "getGroupList",
          params: {
            robotId: wxid,
            cache: 1,
          },
        },
        H: {
          headers: {
            Authorization: this.starBottoken,
          },
        },
      };
      const ret2 = await this.wechatsendapi(date2);
      // console.log(ret2.data);
      // this.qluserlist = [];
      // for (const item of ret2.data.data) {
      //   this.qluserlist.push([item.groupId, item.groupName, item.avatarUrl]);
      // }
      // console.log(this.qluserlist, "微信群列表");
      this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);
      const grdata = ret.data.data.reduce((acc, item) => {
        acc[item.wxId] = { ...item, msgdata: {} }; // 使用 wxid 作为键
        return acc;
      }, {});
      const qldata = ret2.data.data.reduce((acc, item) => {
        acc[item.groupId] = { ...item, msgdata: {} }; // 使用 wxid 作为键
        return acc;
      }, {});
      // console.log(Object.keys(grdata).length, Object.keys(qldata).length, 2222);
      return [grdata, qldata];
    },
    _grqulistindex(e) {
      // console.log(this.gruserlist, this.qluserlist, 111111);
      this.chatobjdata = [];
      this.grqulistindex = e;
    }, // 好友，群列表 定位index
    _chatbuttonindex(index) {
      this.chatbuttonindex = index;
    }, // 聊天上方导航index
    async openWechat() {
      this.tiemMsg = this.$zcx.zcx_msgbox(
        this,
        true,
        { str: "获取微信登录状态...", tiem: 1000 },
        {}
      );
      for (const wxId of this.wxId) {
        const ret2 = await axios.post(
          "/api/processor",
          {
            type: "robotInfo",
            params: {
              robotId: wxId,
              cache: 1,
            },
          },
          {
            headers: {
              Authorization: `0480c6377bfd42368673bfc08ebc44f`,
            },
          }
        );
        if (ret2.data.code === 200) {
          const isExist = this.wechatlist.some((item) => item.wxId === wxId);
          if (!isExist) {
            this.wechatlist.push({
              wxId: wxId,
              wxname: ret2.data.data.nickname,
              image: ret2.data.data.avatarUrl,
            });
          }
        }
        // } else {
        //   this.wechatlist = this.wechatlist.filter(
        //     (item) => item.wxId !== wxId
        //   );
        // }
      }
      this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);
    }, // 获取微信登录状态
  },
};
</script>

<style>
@import "./wechat.less";
</style>
