<template>
  <div class="to2">
    <div class="home-from home-left">
      <div class="home-from" style="margin-top: 3vw; align-items: center">
        <!-- <ZcxAvatar url="https://dl.liandongapp.cn:3001/uploads/dl18190972079/minCP%E5%85%B4%E6%BA%9012R22.5-AR06.png" 
          :size="80"
          /> -->
        <img
          src="../../assets/image/icon/个人中心 (1).png"
          alt=""
          style="width: 2vw; height: 2vw"
        />
        <p style="font-size: 0.5vw; color: #606266; margin-top: 0.5vw">
          {{ mr.username }}
        </p>
      </div>

      <div style="margin-top: 1vw"></div>
      <div
        class="home-left-list home-from"
        v-for="(item, index, index2) in leftdata"
        :key="index2"
        :class="{ mouscss: hoveredIndex === index2 }"
        @mouseover="mous(index2, $event, index)"
        @mouseleave.stop="zcxmouseleave($event)"
      >
        <img
          :src="imageSrc(item.image)"
          alt=""
          @mouseover.stop
          @mouseleave.stop
        />
        <p style="font-size: 0.8vw">
          {{ item.name
          }}<el-icon
            :class="
              hoveredIndex === index2 ? 'el-icon-right' : 'el-icon-arrow-right'
            "
            @mouseover.stop
            @mouseleave.stop
          />
        </p>
      </div>
    </div>
    <div class="home-from home-content">
      <!-- 右侧内容区域 -->
      <div
        class="content-move"
        :style="{ top: mousmove.top + 'px' }"
        v-if="hoveredIndex !== null || contentmove"
        @mouseover="contentmovet"
        @mouseleave="contentmovef"
      >
        <div v-for="(item, index) in contentvalue" :key="index">
          <button @click="buttonclick(index, item)">{{ item[0] }}</button>
        </div>
      </div>
      <div class="content-top">
        <p style="font-size: 1vw">{{ mr.gsname }}</p>
        <div class="content-top-but">
          <div
            class="but-list"
            v-for="(item, index) in butlist"
            :key="index"
            :class="{ butcalss: butlistindex === index }"
            @click="lickindex(index)"
          >
            <p style="margin: auto">{{ item.typename }}</p>
            <p
              class="el-icon-close butxf"
              style="margin-left: auto; z-index: 10"
              v-if="butlistindex === index && index !== 0"
              @click.stop="dlelickindex(index)"
            ></p>
          </div>
        </div>
      </div>
      <!-- <keep-alive> -->
      <div class="content-value home-from">
        <transition name="fade" mode="out-in">
          <keep-alive>
            <component
              :is="currentComponent"
              :ref="`dynamicComponent_${butlistindex}`"
              style="z-index: 1"
            />
          </keep-alive>
        </transition>
      </div>
      <!-- </keep-alive>  -->
    </div>
  </div>
</template>

<script>
// import ZcxAvatar from '@/components/ZcxAvatar.vue';
// import Mall from '@/pages/views/mall/mall.vue';
// import Zy from '@/pages/views/zy/zy.vue';
// import eree from '@/pages/views/eree/eree.vue';
import indexs from "@/router/zcx_indexs";
export default {
  // name: 'App',
  components: {
    // indexs:{...indexs}
  },
  computed: {
    mr() {
      return this.$zcx.zcx_usermr(1).ret;
    },
  },
  data() {
    // const initialIndexsState = JSON.parse(JSON.stringify(indexs));
    return {
      leftdata: {
        xslist: {
          name: "销售管理",
          image: " (47).png",
          list: {
            a: ["销售制单", "eree"],
            b: ["销售退货", "eree"],
          },
        },
        jhlist: { name: "采购管理", image: " (1).png", list: {} },
        kclist: { name: "库存管理", image: " (50).png", list: {} },
        fylist: { name: "费用资金", image: " (48).png", list: {} },
        basicData: { name: "基础资料", image: " (51).png", list: {} },
        zxlist: { name: "征信查询", image: "(67).png", list: {} },
        webmall: {
          name: "商城系统",
          image: " (38).png",
          list: {
            mallsjcp: ["上架产品", "mall"],
            mallhdlist: ["活动列表", "mallList"],
          },
        },
        wechat: {
          name: "智能辅助",
          image: "wxchat.png",
          list: {
            wechatindex1: ["微信机器人", "wechat"],
            wechatset: ["设置", "wechatset"],
          },
        },
        system: {
          name: "系统设置",
          image: " (43).png",
          list: {
            serverusers: ["切换用户", "eree"],
            servertc: ["退出登录", "eree"],
          },
        },
      },
      butlist: [
        {
          type: "index",
          typename: "主页",
          // hash: "mall",
          // component: "zy",
          data: {},
        },
      ],
      butlistindex: 0,
      hoveredIndex: null,
      mousmove: {},
      contentmove: false,
      contentvalue: {},
      timers: "",
      datace: [],
      indexObj: {
        ...indexs,
      },
      currentComponent: null,
    };
  },
  created() {
    try {
      if (this.$zcx.zcx_usermr(1).eree === false) {
        location.hash = "#/";
        return;
      }
      this.butlistindex = 0;
      this.currentComponent = this.indexObj[this.butlist[0].component];
    } catch (error) {
      location.hash = "#/";
    }
  },
  watch: {
    butlistindex(newIndex) {
      this.currentComponent = this.indexObj[this.butlist[newIndex].component];
    },
  },
  methods: {
    lickindex(e) {
      // 切换组件 及标签
      this.butlistindex = e;
    },
    dlelickindex(e) {
      //点击标签关闭按钮时， 向组件传递关闭信息（这里不是完全关闭组件， 是把组件回到初始状态）

      if (e > -1 && e < this.butlist.length) {
        const refName = `dynamicComponent_${e}`;
        if (
          this.$refs[refName] &&
          typeof this.$refs[refName].close === "function"
        ) {
          this.$refs[refName].close();
        }
        this.butlist.splice(e, 1);
      }
      this.butlistindex = 0;
    },
    imageSrc(e) {
      // 修改这个方法来正确处理图片路径
      return require(`@/assets/image/webimage/${e.trim()}`);
    },
    mous(e, event, leftname) {
      //鼠标移动到侧边栏，触发相应的组件
      if (this.hoveredIndex !== e) {
        this.hoveredIndex = e;
        this.mousmove = this.$zcx.div_move(event);
        clearTimeout(this.timers);
        this.timers = "";
        this.contentvalue = {};
        this.contentvalue = { ...this.leftdata[leftname]["list"] };
      }
    },
    zcxmouseleave(event) {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          if (!this.contentmove && this.hoveredIndex !== null) {
            this.hoveredIndex = null;
            this.timers = "";
          }
        }, 500);
        this.timers = this.timeoutId;
      }
    },
    contentmovet() {
      this.contentmove = true;
    },
    contentmovef() {
      this.contentmove = false;
      this.hoveredIndex = null;
    },
    async buttonclick(key, value) {
      if (this.butlist[this.butlistindex].type === key) {
        this.$message({
          type: "warning",
          message: `已在当前页面`,
        });
        return;
      }
      for (const index in this.butlist) {
        if (this.butlist[index].type === key) {
          this.butlistindex = Number(index);
          return;
        }
      }

      let obj = {
        type: key,
        typename: value[0],
        hash: value[1],
        component: value[1],
        data: {},
      };
      this.butlist.push(obj);
      this.butlistindex = this.butlist.length - 1;
      this.currentComponent = await this.indexObj[
        this.butlist[this.butlistindex].component
      ];
      const refName = `dynamicComponent_${this.butlistindex}`;
      if (
        this.$refs[refName] &&
        typeof this.$refs[refName].close === "function"
      ) {
        this.$refs[refName].zcx_created("ke");
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./k.less";
</style>
