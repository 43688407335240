<template>
  <div class="wechat-set">
    <!-- 轮播图部分 -->
    <div class="banner-section">
      <el-carousel height="200px">
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <img :src="item.imgUrl" :alt="item.title" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 内容部分 -->
    <div class="content-section">
      <!-- TOKEN信息 -->
      <el-card class="info-card">
        <div slot="header">
          <span>账户信息</span>
        </div>
        <div class="token-section">
          <div class="account-info-row">
            <!-- 主账户 -->
            <div class="info-item">
              <span class="label">主账户：</span>
              <span class="value">{{
                adminid.substring(0, 2) +
                "********" +
                adminid.substring(adminid.length - 4)
              }}</span>
            </div>
            <!-- 当前账户 -->
            <div class="info-item">
              <span class="label">当前账户：</span>
              <span class="value">{{ adminuser }}</span>
            </div>
            <!-- TOKEN -->
            <div class="info-item token-info" v-if="isMainAccount">
              <span class="label">TOKEN：</span>
              <span class="value">{{
                showToken ? tokenInfo : "************************"
              }}</span>
              <el-button size="small" type="primary" @click="getToken"
                >获取</el-button
              >
              <el-button
                type="text"
                class="eye-btn"
                @click="toggleTokenDisplay"
              >
                <i
                  :class="showToken ? 'el-icon-eye-open' : 'el-icon-eye-close'"
                ></i>
              </el-button>
            </div>
          </div>
        </div>
      </el-card>

      <!-- 服务时间 -->
      <el-card class="info-card">
        <div slot="header">
          <span>服务时间</span>
        </div>
        <div class="service-time">
          <el-progress
            :percentage="serviceTimePercentage"
            :format="formatTime"
          ></el-progress>
          <p>剩余服务时间：{{ remainingDays }}天</p>
        </div>
      </el-card>

      <!-- 绑定账号列表 -->
      <el-card class="info-card">
        <div slot="header">
          <span>客服机账号(WeChat)（{{ boundAccounts.length }}/5）</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            :disabled="boundAccounts.length >= 5"
            @click="handleAddAccount"
          >
            添加账号
          </el-button>
        </div>
        <el-table :data="boundAccounts" style="width: 100%">
          <el-table-column prop="account" label="账号"></el-table-column>
          <el-table-column prop="bindTime" label="绑定时间"></el-table-column>
          <el-table-column label="操作">
            <template>
              <el-button type="text" size="small" @click="handleUnbind"
                >解绑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "WechatSet",
  data() {
    return {
      // 轮播图数据
      bannerList: [
        {
          id: 1,
          imgUrl:
            "https://env-00jxgwvpsgbo.normal.cloudstatic.cn/dlimage/whchatimage/ces.png?expire_at=1735143570&er_sign=eaea5e63f0d11f45270d06ab7079e3ac",
          title: "功能介绍1",
        },
        {
          id: 2,
          imgUrl:
            "https://env-00jxgwvpsgbo.normal.cloudstatic.cn/dlimage/wxid_z4q9wt9lxfiu22/wxid_z4q9wt9lxfiu22-1731994421319.png?expire_at=1735143663&er_sign=2a6464488673be93d81ea6bd4ccfa7d1",
          title: "功能介绍2",
        },
        { id: 3, imgUrl: "banner3.jpg", title: "功能介绍3" },
      ],
      // TOKEN信息
      adminid: "dl18190972079",
      adminuser: "dl18190972079",
      tokenInfo: "",
      // 服务时间相关
      serviceTimePercentage: 70,
      remainingDays: 280,
      // 绑定账号列表
      boundAccounts: [
        { account: "robot001", bindTime: "2024-03-15" },
        { account: "robot002", bindTime: "2024-03-16" },
      ],
      showToken: false,
    };
  },
  computed: {
    // 判断是否为主账户
    isMainAccount() {
      return this.adminid === this.adminuser;
    },
  },
  methods: {
    // 格式化时间显示
    formatTime(percentage) {
      return `${percentage}%`;
    },
    // 添加账号
    handleAddAccount() {
      // 实现添加账号逻辑
    },
    // 解绑账号
    handleUnbind() {
      this.$confirm("确认解绑该账号?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message.success("解绑成功");
        })
        .catch(() => {});
    },
    toggleTokenDisplay() {
      this.showToken = !this.showToken;
    },
    // 添加获取 TOKEN 的方法
    async getToken() {
      try {
        const res = await this.$axios.post("/api/getToken", {
          adminid: this.adminid,
        });
        if (res.data.code === 200) {
          this.tokenInfo = res.data.data.token;
          this.showToken = true; // 获取成功后显示 token
          this.$message.success("TOKEN获取成功");
        } else {
          this.$message.error(res.data.msg || "TOKEN获取失败");
        }
      } catch (error) {
        console.error("获取TOKEN失败:", error);
        this.$message.error("TOKEN获取失败，请稍后重试");
      }
    },
  },
};
</script>

<style>
@import "./wechatset.less";
</style>
